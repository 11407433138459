<template>
    <v-dialog 
    v-model="dialog" 
    :width="width"
    persistent>
		<template v-slot:activator="{ on, attrs }">
            <v-icon dense class="error--text ml-0 mr-3" v-bind="attrs" v-on="on">mdi-delete</v-icon>
		</template>
        <v-card>	
            <v-card-text class="black--text py-2 messageWrapper">
                <p class="font-weight-regular black--text mt-6 messageWrapper">{{lviews.message}} {{title}}?</p>
                <div class="actionButtonsWrapper">
                    <v-btn
                    color="grey"
                    class="mr-0 mt-0"
                     text
                    @click="dialog=false"
                    >
                    {{lbuttons.cancel}}
                    </v-btn>
                    <v-btn
                    color="error"
                    class="mr-0 mt-0"
                    text
                    @click="confirmDelete()"
                    >
                    {{lbuttons.delete}}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {

	name: 'RemoveRecord',

	data: () => ({
        dialog: false
	}),
    props: {
        item: Object,
        title: String,
        // default is 70%, but still do not know how to pass '%' in parameter
        width: {
            type: String,
            default: "70%"
        }
    },
    computed: {
        lviews: { get: function () { return this.$t('views.commons.removeRecordDialog'); } },
		lbuttons: { get: function () { return this.$t('commons.buttons'); } },

    },
    methods: {
        confirmDelete(){
            this.dialog=false;
            this.$emit('confirmDelete', this.item)
        }
    }
	};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.v-btn{
	width: 80px;
}

.messageWrapper{
    font-size: 1em;
}


</style>
